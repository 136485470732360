<template>
  <div>
    {{ $tc("global.valorAdevolver") + ": " }}
    {{ venda_moeda_sigla }}
    {{
      (venda_moeda_sigla !== "G$" && valor_restante * -1 > 0.05) ||
      (venda_moeda_sigla === "G$" && valor_restante * -1 > 100)
        ? valor_restante * -1
        : 0 | guarani
    }}
    <v-row>
      <v-col cols="12" md="2">
        <span> Câmbio {{ hoje }} </span>
        <div v-if="cambios !== null">
          <div>
            U$ 1 =
            <Currency moeda_sigla="R$" :valor="cambios.real" />
          </div>
          <div>
            U$ 1 =
            <Currency moeda_sigla="G$" :valor="cambios.guarani" />
          </div>
          <div>
            R$ 1 =
            <Currency :moeda_sigla="'G$'" :valor="cambios.guarani_real" />
          </div>
        </div>
        <v-spacer></v-spacer>
      </v-col>
      <v-col cols="12" md="10">
        <div class="d-flex">
          <div>
            <v-text-field
              v-model="valor_recebido_guarani"
              outlined
              dense
              class="mr-2"
              v-currency="vCurrencyOptions"
              hide-details
            >
              <template v-slot:prepend-inner
                ><span class="pt-1">G$</span></template
              >
            </v-text-field>
            <span v-if="valor_devolver_guarani * -1 > 100"
              >G$: {{ (valor_devolver_guarani * -1) | guarani }}</span
            >
            <span v-else>G$: 0</span>
          </div>

          <div>
            <v-text-field
              v-model="valor_recebido_dolar"
              outlined
              dense
              class="mr-2"
              v-currency="vCurrencyOptions2"
              hide-details
            >
              <template v-slot:prepend-inner
                ><span class="pt-1">U$</span></template
              >
            </v-text-field>
            <span v-if="valor_devolver_dolar.toFixed(2) * -1 > 0.2"
              >U$: {{ valor_devolver_dolar.toFixed(2) * -1 }}</span
            >
            <span v-else>U$: 0.00</span>
          </div>
          <div>
            <v-text-field
              v-model="valor_recebido_real"
              outlined
              dense
              v-currency="vCurrencyOptions2"
              hide-details
            >
              <template v-slot:prepend-inner
                ><span class="pt-1">R$</span></template
              >
            </v-text-field>
            <span v-if="valor_devolver_real.toFixed(2) * -1 > 0.05"
              >R$: {{ valor_devolver_real.toFixed(2) * -1 }}</span
            >
            <span v-else>R$: 0.00</span>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { cambioDia } from "@/api/cambio/cambios.js";
import { cambioResolve } from "@/helpers/utils";

export default {
  name: "TrocoVenda",

  props: {
    valor_restante: {
      type: [Number, String],
      default: 0,
    },
    venda_moeda_sigla: {
      type: String,
      default: "G$",
    },
  },

  components: {
    Currency: () => import("@/components/global/Currency.vue"),
  },

  data() {
    return {
      hoje: new Date().toLocaleDateString(),
      cambios: null,
      cambio_real: null,
      cambio_guarani: null,
      cambio_dolar: null,
      valor_recebido_guarani: 0,
      valor_recebido_dolar: 0,
      valor_recebido_real: 0,
      disableValorRecebidoGuarani: false,
      i: 0,
    };
  },

  computed: {
    vCurrencyOptions() {
      return {
        precision: 0,
        distractionFree: false,
      };
    },
    vCurrencyOptions2() {
      return {
        precision: 2,
        distractionFree: false,
      };
    },

    total_recebido_consolidado() {
      let total = 0;
      let real = cambioResolve(
        "R$",
        this.$ci.parse(this.valor_recebido_real, this.vCurrencyOptions2),
        this.venda_moeda_sigla,
        Number(this.cambio_real)
      );

      let dolar = cambioResolve(
        "U$",
        this.$ci.parse(this.valor_recebido_dolar, this.vCurrencyOptions2),
        this.venda_moeda_sigla,
        Number(this.cambio_dolar)
      );

      let guarani = cambioResolve(
        "G$",
        this.$ci.parse(this.valor_recebido_guarani, this.vCurrencyOptions2),
        this.venda_moeda_sigla,
        Number(this.cambio_guarani)
      );

      total =
        Math.floor((real + Number.EPSILON) * 100) / 100 +
        Math.floor((dolar + Number.EPSILON) * 100) / 100 +
        Math.floor((guarani + Number.EPSILON) * 100) / 100;

      return total.toFixed(2);
    },

    valor_devolvido_consolidado() {
      let total = 0;

      let real = cambioResolve(
        "R$",
        this.$ci.parse(this.valor_recebido_real, this.vCurrencyOptions2),
        this.venda_moeda_sigla,
        Number(this.cambio_real)
      );
      let dolar = cambioResolve(
        "U$",
        this.$ci.parse(this.valor_recebido_dolar, this.vCurrencyOptions2),
        this.venda_moeda_sigla,
        Number(this.cambio_dolar)
      );

      let guarani = cambioResolve(
        "G$",
        this.$ci.parse(this.valor_recebido_guarani, this.vCurrencyOptions2),
        this.venda_moeda_sigla,
        Number(this.cambio_guarani)
      );
      total = (real + dolar + guarani) * -1;
      return Math.round((total + Number.EPSILON) * 100) / 100;
    },

    valor_devolver_dolar() {
      let devolver_dolar = cambioResolve(
        this.venda_moeda_sigla,
        Number(this.valor_restante) - Number(this.valor_devolvido_consolidado),
        "U$",
        this.cambio_dolar
      );

      return devolver_dolar;
    },

    valor_devolver_real() {
      let devolver_real = cambioResolve(
        this.venda_moeda_sigla,
        Number(this.valor_restante) - Number(this.valor_devolvido_consolidado),
        "R$",
        this.cambio_real
      );

      return devolver_real;
    },

    valor_devolver_guarani() {
      return cambioResolve(
        this.venda_moeda_sigla,
        this.valor_restante - this.valor_devolvido_consolidado,
        "G$",
        this.cambio_guarani
      );
    },

    disableTroco() {
      let result = true;

      if (
        this.venda_moeda_sigla === "G$" &&
        Math.abs(this.total_recebido_consolidado - this.valor_restante * -1) <
          100
      ) {
        result = false;
      } else if (
        this.venda_moeda_sigla !== "G$" &&
        Math.abs(this.total_recebido_consolidado - this.valor_restante * -1) <
          0.05
      ) {
        result = false;
      }

      return result;
    },
  },

  watch: {
    disableTroco() {
      this.$emit("update:disableTroco", this.disableTroco);
    },
    valor_recebido_dolar() {
      this.$emit(
        "update:troco_dolar",
        this.$ci.parse(this.valor_recebido_dolar),
        this.vCurrencyOptions2
      );
    },
    valor_recebido_guarani() {
      this.$emit(
        "update:troco_guarani",
        this.$ci.parse(this.valor_recebido_guarani),
        this.vCurrencyOptions
      );
    },
    valor_recebido_real() {
      this.$emit(
        "update:troco_real",
        this.$ci.parse(this.valor_recebido_real),
        this.vCurrencyOptions2
      );
    },
  },

  methods: {
    setCambio() {
      if (this.venda_moeda_sigla === "G$") {
        this.cambio_real = this.cambios.guarani_real;
        this.cambio_guarani = 1;
        this.cambio_dolar = this.cambios.guarani;
      } else if (this.venda_moeda_sigla === "R$") {
        this.cambio_real = 1;
        this.cambio_guarani = this.cambios.guarani_real;
        this.cambio_dolar = this.cambios.real;
      } else {
        this.cambio_real = this.cambios.real;
        this.cambio_guarani = this.cambios.guarani;
        this.cambio_dolar = 1;
      }
    },
  },

  async mounted() {
    this.cambios = await cambioDia();
    this.setCambio();
    this.$emit("update:disableTroco", this.disableTroco);
  },
};
</script>

<style lang="scss" scoped></style>
